.menu-page {
  padding-left: 20px;
  padding-right: 20px;

  @include lg {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    & + footer {
      display: none !important;

      .inner {
        display: none !important;
      }
    }
  }

  .inner {
    @include lg {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      .constructor-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.export-import {
  display: flex;
  width: 100%;
  height: auto;
  padding: 10px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .item {
    display: flex;
    flex-flow: row nowrap;

    &-icon {
      width: 15px;
      height: auto;
      margin: auto 5px 0;
    }
  }

  @include lg {
    background: $color-gray-light;
    justify-content: space-around;
    position: fixed;
    z-index: 16;
    height: 58px;
    min-width: 350px;
    left: 0;
    bottom: 0;

    .item {
      margin: 0 10px;

      .btn {
        font-size: 14px;
      }
    }
  }

  @include md {
    height: 50px;
  }
}
